(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/protected-route/assets/javascripts/use-auth.js') >= 0) return;  svs.modules.push('/components/lb-utils/protected-route/assets/javascripts/use-auth.js');
"use strict";

const {
  userSession
} = svs.core;
const {
  useMemo
} = React;
const useAuth = roles => {
  const isAnonymous = userSession.isAnonymous();
  const roleIsIncluded = useMemo(() => roles.map(role => userSession.hasRole(role)), [roles]);
  if (isAnonymous || !roleIsIncluded.includes(true)) {
    return false;
  }
  return true;
};
setGlobal('svs.components.lbUtils.protectedRoute.useAuth', useAuth);

 })(window);