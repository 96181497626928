(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/protected-route/assets/javascripts/protected-route.js') >= 0) return;  svs.modules.push('/components/lb-utils/protected-route/assets/javascripts/protected-route.js');
"use strict";

const {
  useAuth
} = svs.components.lbUtils.protectedRoute;
const {
  userSession
} = svs.core;
const {
  INTERNAL,
  RETAILER
} = userSession.roles;
const {
  customer_login
} = svs.components;
const {
  helpers
} = svs.components.lbUtils.protectedRoute;
const ProtectedRoute = _ref => {
  let {
    roles,
    children
  } = _ref;
  const allowedSession = useAuth(roles);
  if (!allowedSession && (userSession.hasRole(RETAILER) || userSession.hasRole(INTERNAL))) {
    helpers.reloadWindow();
    return null;
  } else if (!allowedSession) {
    customer_login.login();
    return null;
  }
  return children;
};
ProtectedRoute.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.arrayOf(PropTypes.string)
};
setGlobal('svs.components.lbUtils.protectedRoute.ProtectedRoute', ProtectedRoute);

 })(window);