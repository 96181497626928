(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/protected-route/assets/javascripts/helpers.js') >= 0) return;  svs.modules.push('/components/lb-utils/protected-route/assets/javascripts/helpers.js');
"use strict";

const reloadWindow = () => {
  window.location.reload();
};
setGlobal('svs.components.lbUtils.protectedRoute.helpers', {
  reloadWindow
});

 })(window);